import { useMutation } from "@apollo/react-hooks";
import { ChoiceList, Modal } from "@shopify/polaris";
import get from "lodash/get";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UPDATE_ORDER_3RD_FULFILLMENT_SHIPPING } from "../../../graphql/mutations";
import { arrInvalid, handleError, objectInvalid } from "../../../helper";
import {
  getPGShippingMethod,
  getPrintifyShippingMethod,
  getPrintwayShippingMethods,
} from "../../base/utils";
import { useToastContext } from "../../shared/ToastContext";

export function UpdateShippingOption({
  value,
  thirdPartyFF,
  open,
  onClose,
  refetch,
  isPrintway,
  isPrintify,
  isPGPrints,
  isPrintBelle,
}) {
  // Props
  const { id, fulfillmentServiceShippingOption } = value || {};
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([null]);

  // Context
  const { toggleToast, setNotify } = useToastContext();
  const refetchTimeoutRef = useRef(null);

  // Mutation
  const [update, { loading }] = useMutation(
    UPDATE_ORDER_3RD_FULFILLMENT_SHIPPING,
    {
      onCompleted: () => {
        setNotify({
          msg: "Update shipping option success.",
          err: false,
        });
        refetchTimeoutRef.current = setTimeout(() => {
          refetch && refetch();
        }, 1500);

        onClose && onClose(false);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  useEffect(() => {
    if (fulfillmentServiceShippingOption == null) return;
    setSelected([fulfillmentServiceShippingOption]);
  }, [fulfillmentServiceShippingOption]);

  useEffect(() => {
    if (thirdPartyFF == null || typeof thirdPartyFF !== "object") return;

    const options = Object.entries(thirdPartyFF).map(([value, label]) => ({
      value,
      label,
    }));
    if (!isPrintBelle) {
      options.unshift({ value: null, label: "Default" });
    }
    setOptions(options);
  }, [thirdPartyFF, isPrintBelle]);

  useEffect(() => {
    if (!isPrintway) return;
    const fulfillmentSku = get(
      value,
      "productVariant.productBaseVariant.fulfillmentSku",
    );

    if (fulfillmentSku) {
      (async function (fulfillmentSku) {
        const shippingServices = await getPrintwayShippingMethods([
          fulfillmentSku,
        ]);
        if (!arrInvalid(shippingServices)) {
          const matchById = shippingServices.find(
            (item) => item.itemSKU === fulfillmentSku,
          );
          if (!objectInvalid(matchById)) {
            if (!arrInvalid(matchById.shippingServices)) {
              const options = matchById.shippingServices.map((method) => ({
                value: method,
                label: method,
              }));

              options.unshift({ value: null, label: "Default" });
              setOptions(options);
            }
          }
        }
      })(fulfillmentSku);
    }
  }, [isPrintway, value]);

  useEffect(() => {
    if (!isPrintify && !isPGPrints) return;
    const baseID = get(
      value,
      "productVariant.productBaseVariant.productBase.id",
    );

    if (baseID) {
      (async function (baseID) {
        if (isPrintify) {
          const shippingServices = await getPrintifyShippingMethod(baseID);
          if (shippingServices.length > 0) {
            setOptions(shippingServices);
          }
        } else if (isPGPrints) {
          const shippingServices = await getPGShippingMethod(baseID);
          if (shippingServices.length > 0) {
            setOptions(shippingServices);
          }
        }
      })(baseID);
    }
  }, [isPrintify, isPGPrints, value]);

  useEffect(
    () => () => {
      refetchTimeoutRef.current && clearTimeout(refetchTimeoutRef.current);
    },
    [],
  );

  // Actions
  const [newShippingOption] = selected || [];
  const handleSubmit = useCallback(() => {
    if (id != null) {
      toggleToast && toggleToast();
      setNotify({ msg: null, err: false });
      update({
        variables: {
          orderId: id,
          newShippingOption,
        },
      });
    }
  }, [newShippingOption, id, update, toggleToast, setNotify]);

  return (
    <Modal
      title="Update shipping option"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <ChoiceList
        choices={options}
        selected={selected}
        title="Shipping Options"
        onChange={(selected) => setSelected(selected)}
      />
    </Modal>
  );
}
