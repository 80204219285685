import React from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { arrInvalid } from "../../../helper";

const GET_TIKTOK_PRODUCT_TEMPLATE_BY_STORE = gql`
  query getTiktokProductTemplateByStore($storeID: ID!) {
    getTiktokProductTemplateByStore(storeID: $storeID) {
      id
      title
      createdBy {
        firstName
        lastName
      }
    }
  }
`;

export default function useTemplateByStore() {
  const [getTemplate, { data, loading }] = useLazyQuery(
    GET_TIKTOK_PRODUCT_TEMPLATE_BY_STORE,
  );

  const handler = React.useCallback(async (storeID) => {
    if (!storeID) return;

    getTemplate({
      variables: {
        storeID,
      },
    });
  }, []);

  return [handler, { data: formatData(data), loading }];
}

function formatData(data) {
  const values = get(data, "getTiktokProductTemplateByStore") || [];
  if (arrInvalid(values)) return null;
  return values.map(i => ({...i, excelTemplateFileID: true}));
}
