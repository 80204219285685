import React, { useCallback, useReducer } from "react";
import { arrInvalid, reducerFn } from "../../../../helper";
import { useSelectRowsCtx } from "../../../shared/SelectRow";
import BulkUpdateMarketplaceManager from "../../components/bulk-update-marketplace-manager";
import { Button } from "@shopify/polaris";

export default function GroupBulkAction({ compact }) {
  const [state, setState] = useReducer(reducerFn, {
    open: false,
    action: null,
  });

  const { ids, onCompleted } = useSelectRowsCtx() || {};

  const toggleShowModal = useCallback((action) => {
    setState((prev) => ({ ...prev, open: !prev.open, action }));
  }, []);

  const propActions = {
    open: state.open,
    toggleShowModal,
    onClose: toggleShowModal,
    onCompleted,
    idsChecked: getStoreIDs(ids, compact),
  };

  const actionsMarkup = {
    updateMarketplaceManager: <BulkUpdateMarketplaceManager {...propActions} />,
  };

  return (
    <>
      <Button
        children="Update Marketplace Manager"
        onClick={() => toggleShowModal("updateMarketplaceManager")}
        disclosure
      />
      {actionsMarkup[state.action]}
    </>
  );
}

function getStoreIDs(groupIds, compact) {
  const res = [];
  for (let groupId of groupIds) {
    const storeIds = compact[groupId];
    if (arrInvalid(storeIds)) continue;

    res.push(...storeIds);
  }

  console.log("res", res);

  return Array.from(new Set([...res]));
}
