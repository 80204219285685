import React from "react";
import { DataTable, Button } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { useAppContext } from "../../../context";
import { arrInvalid, getParamByRole } from "../../../helper";
import GroupDelete from "./GroupDelete";
import SelectRow from "../../shared/SelectRow";
import GroupBulkAction from "./components/group-bulk-action";
import EmptyStatePolaris from "../../shared/EmptyStatePolaris";

const Container = styled.div`
  .stores-wrap {
    width: fit-content;
  }

  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .name-wrap {
    width: calc(18rem - 3.2rem);
  }

  .description-wrap {
    width: calc(20rem - 3.2rem);
  }

  .actions-wrap {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    width: 10rem;
  }
`;

export const GroupsDataTablePolaris = ({
  data,
  filter,
  setFilter,
  refetch,
}) => {
  // State
  //   const [rows, setRows] = React.useState([]);

  // Context
  const { currentUser } = useAppContext();
  const currentParam = getParamByRole(currentUser);

  //   React.useEffect(() => {
  //     let newRows =
  //       data?.groups?.nodes?.length > 0
  //         ? data.groups.nodes.map((group) => {
  //             let { name, description, stores, id } = group;
  //             // Stores
  //             const storeMarkup =
  //               stores?.length > 0
  //                 ? stores.map((store) => store.title).join(", ")
  //                 : null;

  //             return [
  //               <div className="name-wrap item">
  //                 <span>{name}</span>
  //               </div>,
  //               <div className="description-wrap item">
  //                 <span>{description}</span>
  //               </div>,
  //               <div className="stores-wrap item">
  //                 <span>{storeMarkup}</span>
  //               </div>,
  //               <div className="actions-wrap item">
  //                 <Button
  //                   children="Edit"
  //                   plain
  //                   url={`/${currentParam}/stores/groups/edit/${id}`}
  //                 />
  //                 <GroupDelete id={id} refetch={refetch} />
  //               </div>,
  //             ];
  //           })
  //         : [];
  //     setRows(newRows);
  //   }, [data, refetch]);

  // Variables
  let { limit, offset } = filter;
  const total = get(data, "groups.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  const { nodes, compact } = getNodes(data);
  return (
    <>
      <Container>
        <SelectRow
          data={nodes}
          refetch={refetch}
          actionMarkup={<GroupBulkAction compact={compact} />}
        >
          {({ checkItemMarkup, checkAllMarkup }) => {
            const rows = getRows(
              checkItemMarkup,
              nodes,
              currentParam,
              refetch,
              mapTaskFn(nodes),
            );
            if (!rows || arrInvalid(rows)) return <EmptyStatePolaris />;

            return <DataTable
              columnContentTypes={["text", "text", "text", "text", "numeric"]}
              headings={[
                checkAllMarkup,
                "Name",
                "Description",
                "Stores",
                "Actions",
              ]}
              rows={rows}
              verticalAlign="middle"
              hideScrollIndicator
            />;
          }}
        </SelectRow>
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </>
  );
};

function getNodes(data) {
  const nodes = get(data, "groups.nodes") || [];

  const compact = {};
  for (let item of nodes) {
    if (!item || !item.id || !item.stores || item.stores.length === 0) continue;

    const storeIds = [];
    for (let s of item.stores) {
      storeIds.push(s.id);
    }

    compact[item.id] = Array.from(new Set([...storeIds]));
  }

  return { nodes, compact };
}

function mapTaskFn(nodes) {
  const res = {};
  for (let node of nodes) {
    if (!node || !node.id) continue;
    res[node.id] = node;
  }

  return res;
}

function getRows(checkItemMarkup, nodes, currentParam, refetch) {
  const res = [];
  for (let group of nodes) {
    let { name, description, stores, id } = group;
    // Stores
    const storeMarkup =
      stores?.length > 0 ? stores.map((store) => store.title).join(", ") : null;

    res.push([
      checkItemMarkup(id),
      <div className="name-wrap item">
        <span>{name}</span>
      </div>,
      <div className="description-wrap item">
        <span>{description}</span>
      </div>,
      <div className="stores-wrap item">
        <span>{storeMarkup}</span>
      </div>,
      <div className="actions-wrap item">
        <Button
          children="Edit"
          plain
          url={`/${currentParam}/stores/groups/edit/${id}`}
        />
        <GroupDelete id={id} refetch={refetch} />
      </div>,
    ]);
  }

  return res;
}

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;
