import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { BULK_ASSIGN_TO_MARKETPLACE_MANAGER } from "../../../graphql/mutations";
import { arrInvalid, handleError } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { useToastContext } from "../../shared/ToastContext";
import MarketplaceManagerSelect from "../../marketplace-manager/marketplace-manager-select";
import { useSelectRowsCtx } from "../SelectRowsProvider";

function BulkUpdateMarketplaceManager({
  open,
  onClose,
  idsChecked,
  onCompleted: onCompletedProp,
}) {
  const { toggleToast, setNotify } = useToastContext();
  const { ids, onCompleted } = useSelectRowsCtx();

  const [bulkAssignToMarketplaceManager, { loading }] = useMutation(
    BULK_ASSIGN_TO_MARKETPLACE_MANAGER,
    {
      onCompleted: () => {
        setNotify({
          msg: "Update marketplace manager success",
          err: false,
        });
        onCompleted && onCompleted();
        onCompletedProp && onCompletedProp();
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const smRef = useRef(null);

  const handleSubmit = useCallback(() => {
    let mIds = [];
    if (idsChecked?.length > 0) {
      mIds = [...idsChecked];
    } else if (ids?.length > 0) {
      mIds = [...ids];
    }

    if (arrInvalid(mIds)) return;

    const curRef = smRef.current;

    let marketplaceManagerID = "";
    if (curRef) {
      const userIDs = curRef.getSelected();
      if (Array.isArray(userIDs) && userIDs.length > 0) {
        marketplaceManagerID = userIDs[0];
      }
    }
    if (marketplaceManagerID.length == 0) {
      return;
    }

    setNotify({ msg: null, err: false });
    toggleToast(true);

    bulkAssignToMarketplaceManager({
      variables: {
        marketplaceManagerID,
        storeIds: mIds,
      },
    });
  }, [bulkAssignToMarketplaceManager, ids, idsChecked, setNotify, toggleToast]);

  return (
    <Modal
      title="Update marketplace manager"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <MarketplaceManagerSelect
        roles={TEAM_ROLE.MarketplaceManager}
        label="Choose marketplace manager"
        required
        ref={smRef}
      />
    </Modal>
  );
}

export default BulkUpdateMarketplaceManager;
