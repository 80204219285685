import React from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { arrInvalid } from "../../../helper";

const GET_TIKTOK_PRODUCT_TEMPLATE = gql`
  query getTiktokProductTemplate($ID: ID!) {
    getTiktokProductTemplate(ID: $ID) {
      id
      tiktokProductTemplateStores {
        storeID
        store {
          id
          title
          platform
        }
        excelTemplateFile {
          id
          name
          url
          thumbnailUrl
        }
      }
    }
  }
`;

export default function useStoreByTemplate() {
  const [get, { data, loading }] = useLazyQuery(GET_TIKTOK_PRODUCT_TEMPLATE);
  const handler = React.useCallback(async (templateID) => {
    if (!templateID) return;

    get({
      variables: {
        ID: templateID,
      },
    });
  }, []);

  return [handler, { data: formatData(data), loading }];
}

function formatData(data) {
  const values =
    get(data, "getTiktokProductTemplate.tiktokProductTemplateStores") || [];

  const res = [];
  for (let item of values) {
    if (!item || !item.store) continue;
    res.push({ id: item.store.id, title: item.store.title });
  }

  if (arrInvalid(res)) return null;
  return res;
}
