import React from "react";
import { Button, Card, InlineError, Labelled, Stack } from "@shopify/polaris";
import styled from "styled-components";

import { arrInvalid, reducerFn } from "../../../helper";
import TiktokExcelTemplate from "./TiktokExcelTemplate";
import SuspenseComp from "../../shared/SuspenseComp";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { get } from "lodash";

const StoreSelect = React.lazy(() =>
  import("../../store/components/StoreSelect"),
);

const filterObject = { platform: "tiktok" };

const initialValues = {
  storeIDs: [],
  stores: [],
  fileIDs: [],
  file: null,
};

function TiktokProductTemplateStore({ categoryID, data }, ref) {
  const [state, setState] = React.useReducer(reducerFn, {
    data: [initialValues],
    errors: {},
    error: "",
  });

  React.useEffect(() => {
    const newState = getStateFromData(data);
    if (newState?.length > 0) {
      setState({ data: newState });
    }
  }, [JSON.stringify(data)]);

  const handleAddNew = React.useCallback(() => {
    setState({ data: addItem(state.data) });
  }, [state.data]);

  const getInput = React.useCallback(() => {
    setState({ error: "" });

    const isErr = hasError(state.errors);
    if (isErr) return;

    const values = prepareInput(state);
    if (arrInvalid(values)) {
      setState({
        error: "Please choose at least one store and excel template file",
      });
      return;
    }

    return values;
  }, [state]);

  React.useImperativeHandle(ref, () => ({ getInput }));

  return (
    <Card sectioned title="Excel templates">
      <Stack vertical spacing="tight">
        <Wrapper className="store-wrap">
          {state.data?.length > 0
            ? state.data.map((item, idx) => (
                <TiktokProductTemplateStoreItem
                  key={`item-store-${idx}`}
                  index={idx}
                  item={item}
                  setState={setState}
                  categoryID={categoryID}
                  disabled={idx === 0}
                />
              ))
            : null}
        </Wrapper>
        <div>
          {state.error ? <InlineError message={state.error} /> : null}
          <Button
            icon={CirclePlusMinor}
            onClick={handleAddNew}
            children="Add new"
          />
        </div>
      </Stack>
    </Card>
  );
}

function TiktokProductTemplateStoreItem({
  categoryID,
  item,
  index,
  setState,
  disabled,
}) {
  const handleRemove = React.useCallback(
    (index) => () => {
      setState((p) => {
        return {
          ...p,
          data: removeItem(p.data, index),
        };
      });
    },
    [],
  );

  const handleChangeValues = React.useCallback(
    (key, value, errorMessage) => {
      setState((p) => {
        p.data[index] = { ...p.data[index], [key]: value };

        if (key === "fileIDs") {
          p.errors[index] = errorMessage;
          p.error = "";
        }

        return {
          ...p,
          data: [...p.data],
        };
      });
    },
    [index],
  );

  const handleStoreChange = React.useCallback(
    (val) => {
      let newVal = val;
      if (newVal && typeof newVal === "string") {
        newVal = [newVal];
      }

      handleChangeValues("storeIDs", newVal);
    },
    [handleChangeValues],
  );

  const handleExcelFileChange = React.useCallback(
    (value, errorMessage) => {
      if (value && value?.[0] && value?.[0]?.id) {
        handleChangeValues("fileIDs", [value[0].id], errorMessage);
      } else {
        handleChangeValues("fileIDs", [], errorMessage);
      }
    },
    [handleChangeValues],
  );

  const action = disabled
    ? {}
    : { action: { content: "Delete", onAction: handleRemove(index) } };

  return (
    <div className="template-store-item">
      <Labelled label="Store" {...action} />
      <SuspenseComp fallback={null}>
        <StoreSelect
          labelHidden={true}
          value={item.storeIDs}
          onChange={handleStoreChange}
          filter={filterObject} // Only allow select Tiktok store
          // error={fieldError?.storeIDs || null}
          source={item.stores || []}
        />
      </SuspenseComp>
      <div style={{ marginTop: "5px" }}>
        <TiktokExcelTemplate
          tiktokCategoryID={categoryID || null}
          defaultValue={item.file || {}}
          onChange={handleExcelFileChange}
        />
      </div>
    </div>
  );
}

function addItem(data) {
  return [...data, { ...initialValues }];
}

function removeItem(data, index) {
  (data || []).splice(index, 1);
  return data;
}

const hasError = (errors) => Object.values(errors).every((i) => !!i);
const prepareInput = (state) => {
  if (!state || !state.data || arrInvalid(state.data)) return null;

  const res = [];
  for (let item of state.data) {
    if (
      !item ||
      !item.fileIDs ||
      !item.storeIDs ||
      arrInvalid(item.fileIDs) ||
      arrInvalid(item.storeIDs)
    )
      continue;

    res.push({
      storeID: item.storeIDs[0],
      excelTemplateFileID: item.fileIDs[0],
    });
  }

  return res;
};

const getStateFromData = (data) => {
  const values =
    get(data, "getTiktokProductTemplate.tiktokProductTemplateStores") || [];

  const res = [];
  for (let item of values) {
    if (!item) continue;
    const newState = { ...initialValues };
    if (item.storeID) {
      newState.storeIDs = [item.storeID];
      newState.stores = [item.store];
    }

    if (item.excelTemplateFileID) {
      newState.fileIDs = [item.excelTemplateFileID];
      newState.file = item.excelTemplateFile;
    }
    res.push(newState);
  }

  return res;
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;

  .box-flex,
  .box-flex-20 {
    display: inline-block;
    width: 100%;
  }

  .list-file_wrap,
  .file_wrap {
    height: 20rem;
  }

  .file_wrap {
    width: 20rem;
  }

  .file-item {
    justify-content: start;
  }
`;

export default React.forwardRef(TiktokProductTemplateStore);
