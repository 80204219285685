import { Autocomplete, Tag } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import {
  arrInvalid,
  checkRole,
  getUnique,
  handleError,
  reducerFn,
  sleep,
} from "../../../helper";
import { useCallbackRef } from "../../../hooks/useCallbackRef";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { TagContainer } from "../../shared/TagContainer";
import {
  getTiktokProductTemplateMissing,
  useTiktokProductTemplates,
} from "./TiktokProductTemplateHooks";
import { get } from "lodash";

const reducer = (p, s) => ({ ...p, ...s });

function TiktokProductTemplatesSelect({
  value,
  source,
  error,
  allowMultiple,
  label,
  labelHidden,
  required = false,
  fragment = "id title excelTemplateFileID createdBy{ firstName lastName}",
  onChange: onChangeProp,
  filters,
  options: optionsProp,
  loading: loadingProp,
}) {
  // Context
  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    _options: [],
    selected: [],
    inputVal: "",
    selectedOptions: [],
    deselectedOptions: [],
  });

  const [filter, setFilter] = React.useReducer(reducer, {
    limit: 20,
    offset: 0,
    search: "",
  });

  // Hooks
  const {
    data,
    loading,
    error: errorQuery,
  } = useTiktokProductTemplates(filter, `nodes {${fragment}}`, !!optionsProp);

  const INPUT_ID = React.useMemo(() => {
    return "INPUT_COLLECTION_" + Math.random();
  }, []);

  const AUTOCOMPLETE_ID = React.useMemo(
    () => "AUTOCOMPLETE_COLLECTION_" + Math.random(),
    [],
  );
  const COMBOBOX_SELECTOR = React.useMemo(
    () => `div[role="combobox"][aria-controls="${AUTOCOMPLETE_ID}"]`,
    [AUTOCOMPLETE_ID],
  );

  // Effect
  const inputChanged = React.useRef(null);
  React.useEffect(() => {
    if (!!optionsProp) return;
    async function getOptions() {
      const nodes = data?.nodes || [];
      if (nodes?.length === 0) {
        setState({ options: [] });
        return;
      }

      let options = genOptions(filters, nodes);
      if (!inputChanged.current) {
        const opts = genOptions(filters, source);
        const mergedOpts = getUnique([...options, ...opts], "value");
        const ids = genOptions(filters, mergedOpts, ({ value }) => value);

        const optionsOther = await getTiktokProductTemplateMissing(
          ids,
          value,
          fragment,
        );
        const optionsOtherFormat = genOptions(filters, optionsOther);

        options = getUnique([...mergedOpts, ...optionsOtherFormat], "value");
      }

      setState({ options, _options: options });

      // Focus again when selection change
      // When has search value + changed is `false`
      if (inputChanged.current != null && !changedRef.current) {
        const input = document.getElementById(INPUT_ID);
        if (input) {
          input.blur && input.blur();
          await sleep(0);
          input.focus && input.focus();
        }
      }
    }

    getOptions();
  }, [data, source, value, fragment, filters, optionsProp]);

  React.useEffect(() => {
    const options = genOptions(filter, optionsProp);
    if (arrInvalid(options)) {
      setState((p) => ({ ...p, _options: p._options, options: p._options }));
    } else {
      setState((p) => ({ ...p, _options: p.options, options: [...options] }));
    }
  }, [JSON.stringify(optionsProp)]);

  const getSelectedOptions = React.useCallback(
    (selected, deselectedOptions) => {
      const fn = (item) =>
        deselectedOptions.find(({ value }) => value === item);
      return genOptions(filters, selected, fn).filter(Boolean);
    },
    [],
  );

  React.useLayoutEffect(() => {
    let newVal = value;
    if (!Array.isArray(value) && value) {
      newVal = [value];
    }
    if (arrInvalid(newVal)) return;
    const deselectedOptions = getUnique(
      [...state.options, ...state.deselectedOptions],
      "value",
    );

    const selectedOptions = getSelectedOptions(newVal, deselectedOptions);
    setState({ selectedOptions, selected: newVal, deselectedOptions });
  }, [value, state.options, getSelectedOptions]);

  // Actions
  const onChange = useCallbackRef(onChangeProp);
  const changedRef = React.useRef(false);
  const handleSelection = React.useCallback(
    (selected) => {
      const selectedOptions = getSelectedOptions(
        selected,
        getUnique([...state.options, ...state.deselectedOptions], "value"),
      );
      const newState = {
        selected,
        selectedOptions,
      };

      const [firstItem] = selectedOptions || [];
      const { label } = firstItem || {};
      if (!allowMultiple) {
        newState.inputVal = label;
      }

      setState({ ...newState });
      changedRef.current = true;
    },
    [allowMultiple, state.options, state.deselectedOptions, getSelectedOptions],
  );

  const timeoutRef = React.useRef(null);
  const handleInputChange = React.useCallback((search) => {
    setState({ inputVal: search });
    inputChanged.current = !!search;

    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setFilter({ search });
    }, 500);
  }, []);

  const handleRemove = React.useCallback(
    (value) => () => {
      const selected = filterItem(state.selected, (s) => s !== value);
      const selectedOptions = filterItem(
        state.selectedOptions,
        (item) => item.value !== value,
      );

      setState({ selected, selectedOptions });
      allowMultiple && onChange(selected, selectedOptions);
    },
    [state.selected, state.selectedOptions, allowMultiple, onChange],
  );

  React.useLayoutEffect(() => {
    const container = document.querySelector(COMBOBOX_SELECTOR);
    if (container && onChange) {
      function updateValue(e) {
        const expanded = JSON.parse(
          container.getAttribute("aria-expanded") || "",
        );
        if (!expanded && !container.contains(e.target) && changedRef.current) {
          const selectedOptions = state.selectedOptions;
          const selected = state.selected;
          const [firstItem] = selectedOptions || [];
          const { value } = firstItem || {};
          allowMultiple
            ? onChange(selected, selectedOptions)
            : onChange(value, firstItem);

          changedRef.current = false;
          inputChanged.current = null;
        }
      }
      document.addEventListener("click", updateValue);

      return () => {
        document.removeEventListener("click", updateValue);
      };
    }
  }, [state.selectedOptions, state.selected]);

  // Markup
  const textField = (
    <Autocomplete.TextField
      value={state.inputVal}
      onChange={handleInputChange}
      placeholder="Search for Tiktok product template"
      error={error}
      id={INPUT_ID}
    />
  );

  return (
    <React.Fragment>
      {labelHidden ? null : (
        <ComponentLabelPolaris
          label={label ?? "Tiktok product templates"}
          required={required}
        />
      )}
      {errorQuery ? (
        <div>Error: {handleError(errorQuery?.toString())}</div>
      ) : (
        <>
          <Autocomplete
            options={state.options}
            selected={state.selected}
            onSelect={handleSelection}
            textField={textField}
            allowMultiple={allowMultiple}
            emptyState={<span>No items found!</span>}
            loading={loading || loadingProp}
            id={AUTOCOMPLETE_ID}
          />
          <TagContainer>
            {state.selectedOptions?.length > 0
              ? state.selectedOptions.map((opt) => (
                  <Tag
                    children={opt.label}
                    key={opt.value}
                    onRemove={handleRemove(opt.value)}
                  />
                ))
              : null}
          </TagContainer>
        </>
      )}
    </React.Fragment>
  );
}

/**
 * Utils
 */
function filterItem(arr, fn) {
  if (arrInvalid(arr)) return [];

  return arr.filter(fn);
}

function genOptions(
  filters,
  data,
  fn = (item) => {
    const { firstName, lastName } = get(item, "createdBy") || {};
    const fName = [firstName, lastName].filter(Boolean).join(" ");
    const suff = fName ? ` (by: ${fName})` : "";
    let sp = (
      <span
        style={{ fontStyle: "italic", fontWeight: "bold" }}
        dangerouslySetInnerHTML={{ __html: suff }}
      ></span>
    );
    return {
      value: item.id,
      label: (
        <TitleContainer>
          {item.title}
          {sp}
        </TitleContainer>
      ),
      data: item,
    };
  },
) {
  if (arrInvalid(data)) return [];
  const filteredData = filters.must_has_excel_template_file
    ? data.filter(
        (item) =>
          item?.data?.excelTemplateFileID != null ||
          item.excelTemplateFileID != null,
      )
    : data;
  return filteredData.map(fn);
}

const TitleContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default TiktokProductTemplatesSelect;
